import { useCallback, useState } from 'react';
import { useRouter } from 'next/navigation';
import toast from '@/components/atoms/toaster/helpers/toast';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { ApiResult } from '@/types/lib/api';
import { Cart } from '@shared/types/cart';
import { Product } from '../../../../types';

const useProductHandlers = (
  csvProducts: Product[],
  readFiles: Record<string, string>,
  handleClearFiles: () => void,
  addToCart?: (
    lineItems: {
      sku: string;
      count: number;
    }[],
  ) => Promise<ApiResult<Cart> | undefined>,
  handleSKUsUpdate?: (skus: string[]) => void,
  onClose?: () => void,
) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [checked, setChecked] = useState<Record<string, boolean>>({});
  const memoizedClearFiles = useCallback(handleClearFiles, [handleClearFiles]);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { translate } = useTranslation();

  const productMapper = (file: string): Product => {
    const separator = file.includes(';') ? ';' : ',';
    return {
      sku: file.split(separator)[0],
      quantity: parseInt(file.split(separator)[1]),
      inStock: !!csvProducts.find(
        (product) =>
          product.sku === file.split(separator)[0] &&
          product.inStock &&
          product.quantity >= parseInt(file.split(separator)[1]),
      ),
      exists: !!csvProducts.find((product) => product.sku === file.split(separator)[0]),
    };
  };

  const handleUploadClick = (files: File[]) => {
    const newProducts: Product[] = [];
    const newChecked = { ...checked };

    files.forEach((file) => {
      const formattedFile = readFiles[file.name].split('\n').slice(1);
      const skus = formattedFile.reduce<string[]>((acc, file) => {
        const separator = file.includes(';') ? ';' : ',';
        file.split(separator)[0] && acc.push(file.split(separator)[0]);
        return acc;
      }, []);
      handleSKUsUpdate?.(skus);

      formattedFile.forEach((file) => {
        const separator = file.includes(';') ? ';' : ',';
        newChecked[file.split(separator)[0]] = true;

        const product: Product = productMapper(file);
        const target = newProducts.find((productItem) => productItem.sku === product.sku);
        if (target) {
          target.quantity += product.quantity;
        } else if (product.sku) {
          newProducts.push(product);
        }
      });
    });

    setChecked(newChecked);
    setProducts(newProducts);
    setLoading(true);

    const checkedProducts = newProducts.filter((product) => newChecked[product.sku]);
    const lineItems = checkedProducts.map((item) => {
      return { sku: item.sku, count: item.quantity };
    });

    addToCart?.(lineItems)
      .then((resp) => {
        if (resp?.success) {
          setLoading(false);
          onClose?.();
          handleProductClear();
          setChecked({});
          memoizedClearFiles();
          router.refresh();
          router.push('/cart');
          toast.success(translate('quick-order.quick.add.success'), { position: 'top-right' });
        } else {
          setLoading(false);
          toast.error(resp?.message || translate('common.something.went.wrong'), {
            position: 'top-right',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(translate('common.something.went.wrong'), { position: 'top-right' });
      });
  };

  const onCheckboxChange = (product: Product, value: boolean) => {
    const newChecked = { ...checked };
    newChecked[product.sku] = value;
    setChecked(newChecked);
  };

  const handleProductClear = () => setProducts([]);

  const handleAddToCart = () => {
    setLoading(true);
    const inStockProducts = products.filter((product) => checked[product.sku] && product.inStock);
    const lineItems = inStockProducts.map((item) => {
      return { sku: item.sku, count: item.quantity };
    });
    addToCart?.(lineItems).then(() => {
      setLoading(false);
      onClose?.();
      handleProductClear();
      memoizedClearFiles();
    });
  };

  return {
    loading,
    checked,
    products,
    handleUploadClick,
    handleProductClear,
    handleAddToCart,
    onCheckboxChange,
  };
};
export default useProductHandlers;
