import { rememberMeCookieAsync, SDK, ServerOptions } from '@commercetools/frontend-sdk';
import {
  AddAccountAddressAction,
  AddAccountBillingAddressAction,
  AddAccountShippingAddressAction,
  ChangeAccountPasswordAction,
  ConfirmAccountAction,
  DeleteAccountAction,
  GetAccountAction,
  GetAccountActionReturn,
  LoginAccountAction,
  LogoutAccountAction,
  RegisterAccountAction,
  RemoveAccountAddressAction,
  RequestAccountConfirmationEmailAction,
  RequestAccountPasswordResetAction,
  ResetAccountPasswordAction,
  SetDefaultAccountBillingAddressAction,
  SetDefaultAccountShippingAddressAction,
  UpdateAccountAction,
  UpdateAccountAddressAction,
  UpdateSessionDataAction,
} from '../../types/actions/AccountActions';
import {
  AddAccountAddressPayload,
  AddAccountBillingAddressPayload,
  AddAccountShippingAddressPayload,
  ChangeAccountPasswordPayload,
  ConfirmAccountPayload,
  DeleteAccountPayload,
  LoginAccountPayload,
  RegisterAccountPayload,
  RemoveAccountAddressPayload,
  RequestAccountConfirmationEmailPayload,
  RequestAccountPasswordResetPayload,
  ResetAccountPasswordPayload,
  SetDefaultAccountBillingAddressPayload,
  SetDefaultAccountShippingAddressPayload,
  UpdateAccountAddressPayload,
  UpdateAccountPayload,
  UpdateSessionDataPayload,
} from '../../types/payloads/AccountPayloads';
import { ComposableCommerceEventsB2B } from '@/sdk/composable-commerce-b2b';
import { Account } from '@shared/types/account';
import {
  LoginAccountQuery,
  RegisterAccountQuery,
  RequestAccountConfirmationEmailQuery,
} from '../../types/queries/AccountQueries';

export type AccountActions = {
  getAccount: GetAccountAction;
  login: LoginAccountAction;
  logout: LogoutAccountAction;
  register: RegisterAccountAction;
  confirm: ConfirmAccountAction;
  requestConfirmationEmail: RequestAccountConfirmationEmailAction;
  changePassword: ChangeAccountPasswordAction;
  requestPasswordReset: RequestAccountPasswordResetAction;
  resetPassword: ResetAccountPasswordAction;
  updateAccount: UpdateAccountAction;
  addAddress: AddAccountAddressAction;
  updateAddress: UpdateAccountAddressAction;
  addBillingAddress: AddAccountBillingAddressAction;
  addShippingAddress: AddAccountShippingAddressAction;
  removeAddress: RemoveAccountAddressAction;
  setDefaultBillingAddress: SetDefaultAccountBillingAddressAction;
  setDefaultShippingAddress: SetDefaultAccountShippingAddressAction;
  deleteAccount: DeleteAccountAction;
  updateSessionData: UpdateSessionDataAction;
};

export const getAccountActions = (sdk: SDK<ComposableCommerceEventsB2B>): AccountActions => {
  return {
    getAccount: async (options?: { serverOptions?: ServerOptions }) => {
      return await sdk.callAction<GetAccountActionReturn>({
        actionName: 'account/getAccount',
        serverOptions: options?.serverOptions,
      });
    },
    login: async (
      payload: LoginAccountPayload,
      query?: LoginAccountQuery,
      options: {
        serverOptions?: ServerOptions;
      } = {},
    ) => {
      const remember = payload.remember;
      payload.remember = undefined;

      const response = await sdk.callAction<Account>({
        actionName: 'account/login',
        payload,
        query,
        serverOptions: options?.serverOptions,
      });

      if (!response.isError) {
        if (remember) {
          await rememberMeCookieAsync.set(true, options.serverOptions);
        }
      }

      return response;
    },
    logout: async (options: { serverOptions?: ServerOptions } = {}) => {
      const response = await sdk.callAction<void>({
        actionName: 'account/logout',
        serverOptions: options?.serverOptions,
      });
      if (!response.isError) {
        await rememberMeCookieAsync.remove(options.serverOptions);
      }
      return response;
    },
    register: async (
      payload: RegisterAccountPayload,
      query?: RegisterAccountQuery,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/register',
        payload,
        query,
        serverOptions: options?.serverOptions,
      });
    },
    confirm: async (
      payload: ConfirmAccountPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/confirm',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    requestConfirmationEmail: async (
      payload: RequestAccountConfirmationEmailPayload,
      query?: RequestAccountConfirmationEmailQuery,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<void>({
        actionName: 'account/requestConfirmationEmail',
        payload,
        query,
        serverOptions: options?.serverOptions,
      });
    },
    changePassword: async (
      payload: ChangeAccountPasswordPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/password',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    requestPasswordReset: async (
      payload: RequestAccountPasswordResetPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<void>({
        actionName: 'account/requestReset',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    resetPassword: async (
      payload: ResetAccountPasswordPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/reset',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    updateAccount: async (
      payload: UpdateAccountPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/update',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    addAddress: async (
      payload: AddAccountAddressPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/addAddress',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    updateAddress: async (
      payload: UpdateAccountAddressPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/updateAddress',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    addBillingAddress: async (
      payload: AddAccountBillingAddressPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/addBillingAddress',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    addShippingAddress: async (
      payload: AddAccountShippingAddressPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/addShippingAddress',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    removeAddress: async (
      payload: RemoveAccountAddressPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/removeAddress',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    setDefaultBillingAddress: async (
      payload: SetDefaultAccountBillingAddressPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/setDefaultBillingAddress',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    setDefaultShippingAddress: async (
      payload: SetDefaultAccountShippingAddressPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<Account>({
        actionName: 'account/setDefaultShippingAddress',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    deleteAccount: async (
      payload: DeleteAccountPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      return await sdk.callAction<void>({
        actionName: 'account/deleteAccount',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
    updateSessionData: async (
      payload: UpdateSessionDataPayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      await sdk.callAction<void>({
        actionName: 'account/updateSessionData',
        payload,
        serverOptions: options?.serverOptions,
      });
    },
  };
};
